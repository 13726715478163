$('document').ready( function(){
	
	var swipers = {};
	var galleries = {};
		
	$('.swiper-container').each(function(){
		
		var id = $(this).attr('id');
		swipers[id] = new Swiper('#' + id, {
	    	loop: true,
	    	pagination: {
				el: '#' + id + ' .swiper-pagination',
    		},
			navigation: {
				nextEl: '#' + id + ' .swiper-button-next',
				prevEl: '#' + id + ' .swiper-button-prev'
    		},
		});
		
		$(this).find('.swiper-slide a').click(function(){
			
			var id = $(this).parents('.swiper-container').attr('id');
			var swp = swipers[id];
			var imagesList = [];
			var currentBg = $(this).parent().css('background-image').replace('url(','').replace(')','').replace(/\"/gi, "");
			
			var index = 0,
				startIndex = 0;
				
			swp.slides.each(function(){
				if( !$(this).hasClass('swiper-slide-duplicate')) {
					var bg = $(this).css('background-image').replace('url(','').replace(')','').replace(/\"/gi, "");
					var img = $(this).find('img');
					imagesList.push({
						src: bg,
						w: img.width(),
						h: img.height(),
						title: $(this).data('caption')
					});
					if(currentBg == bg) startIndex = index;
					index++;
				}					
			});
			
			var options = {
    			index: startIndex
			};
						
			//if(galleries.hasOwnProperty(id)) galleries[id].destroy();
			galleries[id] = new PhotoSwipe( $('.pswp').get(0) , PhotoSwipeUI_Default, imagesList, options);
			galleries[id].init();
			
			return false;
		});
		
	});
});

// thumbs-gallery
$('document').ready( function(){
	const swiperGalleriesImages = document.querySelectorAll('.swiper-gallery-image');
	const swiperGalleriesThumbs = document.querySelectorAll('.swiper-gallery-thumbs');

	swiperGalleriesImages.forEach((swiperImage, i) => {
		new Swiper(swiperImage, {
			spaceBetween: 36,
			slidesPerView: 4,
			freeMode: true,
			watchSlidesProgress: true,
		});
		new Swiper(swiperGalleriesThumbs[i], {
			spaceBetween: 0,
			navigation: {
				nextEl: ".swiper-button-next",
				prevEl: ".swiper-button-prev",
			},
			thumbs: {
				swiper: swiperImage,
			},
		});
	});

});

// half-full gallery
$('document').ready( function(){

	const swiperGalleries = document.querySelectorAll('.swiper-container-halfscreen');

	swiperGalleries.forEach(swiperElement => {
		new Swiper(swiperElement, {
			loop: true,
			speed: 2000,
			direction: "horizontal",
			// If we need pagination
			pagination: {
				el: ".swiper-pagination",
				type: "bullets",
				dynamicBullets: true,
			},
			autoplay: {
				delay: 4000,
			},
			}).autoplay.start();
	});

});

// Vertiacal top
$('document').ready( function(){
	window.addEventListener("scroll", function () {
		var scrollAmount = window.scrollY;
		var slideScrollContainer = document.querySelector(".slide-scroll-container");
		if (slideScrollContainer) {
			
			var containerOffset = slideScrollContainer.offsetTop - 300 ;
			var containerHeight = slideScrollContainer.offsetHeight / 4;
			var slide2 = slideScrollContainer.children[1];

			// console.log('scrollAmount', scrollAmount);
			// console.log(containerOffset, containerOffset + containerHeight);
	
			if (
				scrollAmount > containerOffset &&
				scrollAmount < containerOffset + containerHeight
			) {
				var percentage = (scrollAmount - containerOffset) / containerHeight;
				var moveUp = 100 - percentage * 100;
				slide2.style.transform = "translateY(-" + moveUp + "%)";
			} else if (scrollAmount < containerOffset || scrollAmount == containerOffset) {
				slide2.style.transform = "translateY(-100%)";
			} else {
				slide2.style.transform = "translateY(0%)";
			}
		}

	});
});

// full screen slider
$('document').ready(function() {

	const swiperFull = document.querySelectorAll('.swiper-container-fullscreen');

	swiperFull.forEach(swiperElement => {
		new Swiper(swiperElement, {
			loop: true,
			speed: 2000,
			direction: "horizontal",
			autoplay: {
				delay: 4000,
			},
		}).autoplay.start();
	});
})

// accessoires 
$('document').ready(function() {
	
	let swiperAccessoiresThumbs = null;
	let swiperAccessoiresImage = null;
	let screenWidth = window.screen.width;
	if (screenWidth > 768) { initThumbs() }
	function initThumbs() {
		if (!swiperAccessoiresThumbs) {

			swiperAccessoiresThumbs = document.querySelector(".swiper-accessoires-thumbs");
			if (!swiperAccessoiresThumbs) {
				return false
			}
			swiperAccessoiresThumbs = new Swiper(".swiper-accessoires-thumbs", {
				spaceBetween: 36,
				slidesPerView: "auto",
				// slidesPerGroup: 2,
				// centerInsufficientSlides:true,
				// slideToClickedSlide: true,
				// freeMode: false,
				// centeredSlides: true,
				observer: true,
				observeParents: true,
				direction: "vertical",
				navigation: {
					nextEl: ".swiper-button-next",
					prevEl: ".swiper-button-prev",
				},
				on: {
					init: function () {
						this.update()
						this.snapGrid = [...this.slidesGrid];
					},
					reachEnd: function() {
						console.log(this.snapGrid);
						this.snapGrid = [...this.slidesGrid];
						setTimeout(() => {
							document.querySelector('.swiper-accessoires-thumbs .swiper-button-next').click()
							clearTimeout()
						}, 1);
					},
					snapGridLengthChange: function(){
						if( this.snapGrid.length != this.slidesGrid.length ){
							this.snapGrid = this.slidesGrid.slice(0);
						}
					},
					slideChange: function () {
						this.snapGrid = [...this.slidesGrid];
						this.slidesSizesGrid.forEach(el => {
							el = this.snapGrid[1]
						})
						console.log(this);
						let height = this.height;
						let translate = this.translate

						console.log(height, translate, this.activeIndex);
						// if ((this.activeIndex + 1) == this.slides.length) {
						// 	swiperAccessoiresThumbs.height
						// } else {
						// 	swiperAccessoiresThumbs.height = swiperAccessoiresThumbs.height + (this.slides.length * 100)
						// }
					}
				}
			});
		}
	}

	window.addEventListener("resize", function () {
		let screenWidth = window.screen.width;
		if (screenWidth > 768) {
			initThumbs()
			/*
			if (!swiperAccessoiresThumbs) {
				swiperAccessoiresThumbs = new Swiper(".swiper-accessoires-thumbs", {
					spaceBetween: 36,
					slidesPerView: "auto",
					// centeredSlides: true,
					freeMode: true,
					// autoScrollOffset: 1,
					resizeObserver: true,
					// slideToClickedSlide: true,
					// autoHeight: true, 
					loop: true, 
					// direction: window.screen.width >= 768 ? "vertical" : "horizontal",
					// direction: "horizontal",
					// breakpoints: {
					// 	0: {
					// 	centeredSlides: true,
					// 	slidesPerView: "auto",
					// 	},
					navigation: {
						nextEl: ".swiper-button-next",
						prevEl: ".swiper-button-prev",
					},
					// },
				});
			}
			*/
		}
	});

	if (document.querySelector(".swiper-accessoires-image")) {
		swiperAccessoiresImage = new Swiper(".swiper-accessoires-image", {
			spaceBetween: 10,
			loop: true,
			effect: "fade",
			fadeEffect: {
				crossFade: true,
			},
			thumbs: {
				swiper: swiperAccessoiresThumbs,
			},
			navigation: {
				nextEl: ".swiper-button-next",
				prevEl: ".swiper-button-prev",
			},
			on: {
				init: function () {
					let screenWidth = window.screen.width;
					if (screenWidth < 768) {
						let activeIndex = this.activeIndex + 1;
						let activeSlide = document.querySelector(`.swiper-accessoires-image .swiper-slide:nth-child(${activeIndex})`);
						let activeText = activeSlide.querySelector(':first-child');
						let activePicture = activeSlide.querySelector('picture');
						let wrapper = document.querySelector(`.swiper-accessoires-image .swiper-wrapper`)
						wrapper.style.height = `${activeText.offsetHeight + activePicture.offsetHeight + 40}px`;
					}
				},
				slideChange: function () {
					let screenWidth = window.screen.width;
					if (screenWidth < 768) {
						let activeIndex = this.activeIndex + 1;
						let activeSlide = document.querySelector(`.swiper-accessoires-image .swiper-slide:nth-child(${activeIndex})`);
						let activeText = activeSlide.querySelector(':first-child');
						let activePicture = activeSlide.querySelector('picture');
						let wrapper = document.querySelector(`.swiper-accessoires-image .swiper-wrapper`)
						wrapper.style.height = `${activeText.offsetHeight + activePicture.offsetHeight + 40}px`;
					}
				}
			},
		});
	}


	// let swiperAccessoiresThumbs = new Swiper(".swiper-accessoires-thumbs", swiperOptions);
	// swiperAccessoiresThumbs.on('click', function(slide, event) {
	// 	console.log(slide, event);
	// })

	// window.addEventListener("resize", function () {
	// 	let newDirection = window.screen.width >= 768 ? "vertical" : "horizontal";
	// 	if (newDirection !== swiperAccessoiresThumbs.params.direction) {
	// 		swiperAccessoiresThumbs.destroy(true, true); 
	// 		swiperOptions.direction = newDirection; 
	// 		swiperAccessoiresThumbs = new Swiper(".swiper-accessoires-thumbs", swiperOptions);
	// 	}
	// });
})